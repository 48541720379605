<template>
    <div>
      <Toolbar>
        <template #start>
            <h3>
              {{ $t(gameFilters) }}
            </h3>
        </template>
        <template #end>
          <Button :disabled="loadingFlag" :loading="loadingFlag" :label="$t('set_new_min_bet')" icon="pi pi-save" class="p-button-sm p-button-success" />
          </template>
    </Toolbar>
      <v-row class="layout__row ma-0">
        <v-col class="mt-1 layout__col">
          <div class="my-1" style="color: var(--text-color)">
            <h3 class="text-uppercase"> {{ $t('games') }} </h3>
          </div>
          <template v-if="!startLoadingFlag">
            <div class="main-item" v-if="gameSettingItems && gameSettingItems.items && gameSettingItems.total !== 0" :class="{ '_loading': loadingFlag }">
                  <div class="main-item__game" @click="selectProviderByGame(item.prov_name)"
                  v-for="(item, index) in gameSettingItems.items" :key="index"
                  :class="{ '_no-active': !item.active }"
                  >
                    <div class="main-item__image" v-if="item.logo">
                      <span>{{ index + 1 }}</span>
                      <v-lazy v-model="isActive">
      
                        <v-img max-height="50px" max-width="50px" 
                        @load="isActive = true" 
                        :src="item.logo" 
                        :alt="item.name" />
                      </v-lazy>
                    </div>
                    <div class="main-item__name">
                      {{ item ? item.name : '' }}
                      <span class="ml-1" v-if="!item.active"> ({{ $t('not_active') }})</span>
                    </div>
                    <!-- <div class="main-item__actions">
                      <v-btn class="main-item__edit main-btn" @click="openEditGameDialog(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </div> -->
                  </div>
              <div v-if="this.game.page !== this.game.pages">
                <Button @click="oneMorePageGames" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('more')"
                  icon="pi pi-save" class="p-button-sm p-button-success" />
                <div v-intersect="showMoreGamesIntersect"></div>
            </div>
            </div>
              <h2 v-if="!loadingFlag && !gameSettingItems && !gameSettingItems.items" class="text-uppercase my-1 c-red"> {{ $t('gameNotFound') }}
              </h2>
          </template>
          <ProgressBar v-else mode="indeterminate" style="height: .5em" />
        </v-col>
        <div class="line"></div>
        <v-col class="mt-1 layout__col">
          <div class="my-1" style="color: var(--text-color)">
            <h3 class="text-uppercase"> {{ $t('game_studios') }} </h3>
          </div>
          <h2 v-if="!provider.length > 0 && !loadingFlag" class="text-uppercase my-1 c-red"> {{ $t('game_studios_not_found')
          }} </h2>
          <div class="main-item">
                <div 
                v-for="(item, index) in providerArray" :key="index"
                class="main-item__game _provider" 
                :class="{ '_active': item.string_id === filters.provider, '_no-active': item.is_enabled === false }"
                @click="selectProvider(item)">
                  <div class="main-item__image" v-if="item.logo">
                    <span>{{ index + 1 }}</span>
                    <v-lazy v-model="isActive">
                      <v-img max-height="50px" max-width="50px" 
                      @load="isActive = true" 
                      :src="item.logo" 
                      :alt="item.name" />
                    </v-lazy>
                  </div>
                  <div class="main-item__name">
                    <!-- (${item.total_games} ${$t('ga')}) -->
                    <span>{{ item ? `${item.name} ` : '' }} </span>
                    <span class="ml-1" v-if="!item.is_enabled"> ({{ $t('not_active') }})</span>
                  </div>
                  <!-- <div class="main-item__actions">
    
                    <v-btn class="main-item__edit main-btn" @click="openEditProviderDialog(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </div> -->
              </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </template>
    
  <script>
  
  import axios from "axios";
  import { mapGetters } from "vuex";
  export default {
    name: "TabGameSettings",
    components: {
    },
    props: {
      gameFilters: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        isActive: false,
        loadingFlag: true,
        startLoadingFlag: false,
        startPage: 1,
        globalSize: 100,
        activeFilters: {},
        isIntersectGames: false,
        filters:{
          page: 1,
          size: 100,
        },
      }
    },
    async mounted() {
      this.startLoadingFlag = true;
        //await this.holdProviderToGame('yggdrasil');
        await this.applyFilters();
      this.startLoadingFlag = false;
    },
    computed: {
      ...mapGetters({
        game: 'gameSettings/getGameSettings',
        provider: 'gameProviders',
      }),
      providerArray(){
        return this.provider.filter(prov => prov.name !== 'All Games');
      },
      gameSettingItems() {
      const filteredItem = Object.keys(this.game).find(item => item === this.gameFilters);
      if (filteredItem) {
          return {
              items: this.game[filteredItem].items,
              page: this.game[filteredItem].page,
              pages: this.game[filteredItem].pages,
              size: this.game[filteredItem].size,
              total: this.game[filteredItem].total
          };
      } else {
        return null; 
    }
},
    },
    methods: {
      selectProviderByGame(provName){
        const findProvider = this.provider.find(prov => prov.name === provName);
        this.selectProvider(findProvider);
      },
      //========================================================================================================================================================
      //async holdProviderToGame(providerName) {
      //  this.$store.commit('gameModule/SET_PROVIDER_FILTER', providerName);
      //  this.$root.$emit('selectProvider');
      //},
      async selectProvider(provider) {
        if (this.filters.provider === provider.string_id) {
          return;
        }
        // console.log(provider);
        this.filters.page = this.page = 1;
        this.filters.size = this.globalSize;
        this.filters.provider = provider.string_id;
        //this.holdProviderToGame(provider.string_id);
        await this.awaitGetSomeGames();
      },
      async applyFilters() {
        this.filters.page = this.page = 1;
        this.filters.size = this.globalSize;
        await this.awaitGetSomeGames();
      },
      showMoreGamesIntersect(entries, observer) {
        // check instersect block
        if (entries[0].isIntersecting && this.isIntersectGames) {
          // call
          this.loadingFlag = true;
          this.oneMorePageGames();
        }
      },
      async oneMorePageGames(){
        this.isIntersectGames = true;
        this.filters.page += this.page;
        this.filters.size = this.globalSize;
        await this.awaitGetSomeGames('one_more');
      },
      async awaitGetSomeGames(condition){
      this.loadingFlag = true;
        const gameType = this.filters.game_type = this.gameFilters;
        if (condition !== 'one_more') {
          const res = await this.$store.dispatch('gameSettings/getGameSettings', this.filters);
          console.log(res);
          // this.$router.push({ query: { game_type: gameType }});
          if (!res) {
            this.$emit('hold-error');
            this.loadingFlag = false;
            return;
          }
        } else{
          await this.$store.dispatch('getOneMorePageGames', this.filters);
        }
        this.loadingFlag = false;
        //this.setLocalOrders();
        //this.setFilters();
      },
      //setFilters() {
      //  const filters = {};
      //  for (const [key, value] of Object.entries(this.filters)) {
      //    if (value !== '') {
      //      filters[key] = value;
      //    }
      //  }
      //  this.$store.commit('gameModule/SET_FILTERS', filters);
      //},
      //async setLocalOrders() {
      //  if (this.game && this.game.items) {
      //    this.localGamesOrder = [...this.game.items];
      //  }
      //  if (this.provider) {
      //    this.localProviderOrder = [...this.provider].filter(item => item.value !== "" && item.value !== 'All Games');
      //  }
      //},
      //========================================================================================================================================================
  
      getFilterValueTitle(key) {
        // title chips
        switch (key) {
  
          case 'name':
            return this.$t('game_name')
          case 'provider':
            return this.$t('provider')
        }
  
        return key;
      },
      getFilterValueText(key, value) {
        // text chips
  
        return value;
      },
      //========================================================================================================================================================
      async removeFilter(key) {
        this.loadingFlag = true;
        this.$delete(this.activeFilters, key);
        if (typeof this.filters[key] === 'boolean') {
          this.$set(this.filters, key, false);
        } else {
          this.$set(this.filters, key, '');
        }
        await this.$store.dispatch('getGames', this.filters);
        this.setLocalOrders();
        this.loadingFlag = false;
      },
    },
  
  }
  </script>
    
  <style lang="scss" scoped>
  ._cursor-grab {
    cursor: grab;
  }
  
  ._cursor-pointer {
    cursor: pointer;
  }
  .layout {
  
    @media (max-width:850px) {
      min-height: 100%;
    }
  
    &__row {
      @media (max-width:850px) {
        display: block;
      }
    }
  
    &__col {
      
    }
  }
  
  .line {
    width: 1px;
    border: 2px dashed var(--surface-400) !important;
  
    @media (max-width:850px) {
      display: none;
    }
  }
  
  .custom-overlay {
    background-color: rgb(255 255 255 / 0.3) !important;
    backdrop-filter: blur(10px);
  }
  
  
  .main-item {
    overflow: auto;
    max-height: 575px;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    position: relative;
    height: 100%;
    padding-top: 3px;
    &__game {
      padding: 3px 8px;
      display: flex;
      align-items: center;
      border: 1px solid var(--surface-border);
  
      &:nth-of-type(odd) {
        background-color: var(--surface-100);
      }
  
      &:nth-of-type(even) {
        background-color: var(--surface-300);
      }
  
      &._provider  {
        &:nth-of-type(even) {
          background-color: var(--surface-100);
        }
  
        &:nth-of-type(odd) {
          background-color:  var(--surface-300);
        }
      }
  
      &._active {
        border: 1px solid var(--pink-700);
        background-color: var(--pink-200) !important;
      }
    }
  
    &__drag {
      margin-right: 5px;
      i{
        color: var(--text-color-secondary);
        ._active &{
          color: var(--bluegray-900);
        } 
      }
    }
    
    &__image {
      flex: 0 0 50px;
      width: 50px;
      height: 50px;
      position: relative;
      margin-right: 4px;
      display: flex;
      
      ._provider & {
        padding: 0 2px;
        background-color: var(--main-flamingo);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 16px;
      }
  
      ._no-active & {
        filter: grayscale(1);
      }
  
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--red);
        color: var(--white);
        min-width: 20px;
        height: 20px;
        position: absolute;
        padding: 0 3px;
        border-radius: 50px;
        right: -5px;
        top: -5px;
        z-index: 2;
      }
  
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 16px;
        ._provider & {
          border-radius: 0px;
        }
      }
    }
    
    &__name {
      flex: 1 1 100%;
      margin: 0 8px;
      color: var(--text-color);
      text-transform: capitalize;
      ._provider & {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ._active &{
        color: var(--bluegray-900);
      }
    }
  
    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &__edit {}
  }
  
  
  
  </style>
    