import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c('div',{staticClass:"main-content d-flex flex-column"},[_c('Card',{staticClass:"card-custom",scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c(VTabs,{staticClass:"main-tabs",attrs:{"background-color":"transparent"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab,index){return _c(VTab,{key:index,on:{"change":function($event){return _vm.toChangeTab(tab)}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":tab.iconColor}},[_vm._v(_vm._s(tab.icon))]),_vm._v(" "+_vm._s(_vm.$t(tab.title))+" ")],1)}),1),_c(VTabsItems,{staticClass:"mt-1",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab,index){return _c(VTabItem,{key:index,attrs:{"transition":false,"reverse-transition":false}},[_c('tab-game-settings',{attrs:{"gameFilters":_vm.gameFilters},on:{"hold-error":_vm.toActiveErrorFlag}})],1)}),1)],1)]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }