<template>
    <v-main>
        <div class="main-content d-flex flex-column">
            <Card class="card-custom">
                <template #content>
                    <div>
                        <v-tabs v-model="selectedTab" class="main-tabs" background-color="transparent">
                            <v-tab v-for="(tab, index) in tabs" :key="index" @change="toChangeTab(tab)">
                              <v-icon class="mr-2" :color="tab.iconColor">{{ tab.icon }}</v-icon>
                              {{ $t(tab.title) }}
                            </v-tab>
                          </v-tabs>
                          <v-tabs-items class="mt-1" v-model="selectedTab">
                            <v-tab-item v-for="(tab, index) in tabs" :key="index" :transition="false" :reverse-transition="false">
                              <tab-game-settings
                              :gameFilters="gameFilters" @hold-error="toActiveErrorFlag" />
                            </v-tab-item>
                          </v-tabs-items>
                    </div>
                    <!-- v-show="!dataError"
                        <no-work-page 
                        v-if="dataError"
                    @refresh-request="applyFilters" 
                    :loadingFlag="refreshFlag"
                    :textError="textError"
                    /> -->
                </template>
            </Card>
        </div>
    </v-main>
</template>

<script>
import NoWorkPage from '@/components/NoWorkPage'
import TabGameSettings from './TabGameSettings'
    export default {
        components:{
            NoWorkPage,
            TabGameSettings,
        },
        name: 'GameSettings',
        data() {
            return {
                refreshFlag: false,
                dataError: false,
                textError: 'game_setting_error',
                selectedTab: 0,
                gameFilters: 'slots',
                tabs: [
        { code: 'slots', title: 'slots', icon: 'mdi-gamepad', iconColor: '' },
        { code: 'roulette', title: 'roulette', icon: 'fas fa-dice', iconColor: '' },
        { code: 'crash', title: 'crash', icon: 'mdi-chart-line', iconColor: '' },
        { code: 'fishing', title: 'fishing', icon: 'mdi-fish', iconColor: '' }
      ],
                
                filters:{
                },
                startPage: 1,
                globalSize: 100,
            }
        },
        async beforeRouteEnter (to, from, next) {
            await next(vm => {
                vm.$store.commit('setPageName', 'game_settings')
                vm.$store.dispatch('getGamesProviders');
            })
        },
        async mounted(){
        },
        methods:{
            toChangeTab(tab){
                this.gameFilters = tab.code;
            },
            async applyFilters(){
                this.refreshFlag = true;
                this.isIntersectGames = true;
                this.filters.page = this.startPage;
                this.filters.size = this.globalSize;
                this.filters.game_type = this.gameFilters;
                const res = await this.$store.dispatch('gameSettings/getGameSettings', this.filters);
                if (res) {
                    this.dataError = false;
                } else{
                    this.refreshFlag = false;
                }
            },
            toActiveErrorFlag(){
                this.dataError = true;
                this.refreshFlag = false;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>